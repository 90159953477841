import { createContext, useMemo, useReducer, useContext, useEffect, useState } from 'react'
import {Route, Switch, useHistory} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useMutation } from 'react-query'

import { GetUserFundAccesses, GetUsersConfigurationData, ValidateUserAssetCountry } from '../../../../services/login-service.js'
import { addAccessTokenInterceptor } from '../../../../services/common/axios-config'
import axiosAuthInstance from '../../../../services/common/axios-auth-config'
import { getFeatureFlagsValue } from '../../../common/feature-flags/FeatureFlagUtil'
import { featureFlagInit } from '../../../../utils/reducers/FeatureFlagReducer'
import {CachedUserState, SessionUserState, getHomePagePathV2} from '../../../../utils/helpers/Helper.js'
import { LOCAL_STORAGE_KEYS, USER_FUND_ACCESSES } from '../../../../utils/helpers/Constants.js'
import AuthError from '../../errors/AuthError'
import { FeatureFlagContext } from 'Contexts'
import { Auth0Login } from './Login.js'
import { SwitchInstance } from './SwitchInstance'
import {unsavedChanges} from '../../../../utils/signals/CommonSignals'
import { LOCALES } from 'utils/i18n/constants.js'
import { I18nProvider } from 'utils/i18n/index.js'

const UserInfoContext = createContext()

const loginStateInit = {
  isLoggedIn: false,
  isLoading: false,
  isLogInSuccess: false,
  userFundAccess: null,
  user: {},
  userInfo: {},
  homePath: null
}

const loginReducer = (state, action) => {
  switch(action.type) {
  case 'UPDATE_USER':             return {...state, user: action.user}
  case 'SET_USER_INFO':           return {...state, userInfo: action.userInfo}
  case 'UPDATE_USER_FUND_ACCESS': return {...state, userFundAccess: action.userFundAccess}
  case 'UPDATE_LOGIN_STATE':      return {...state, isLoggedIn: action.isLoggedIn}
  case 'UPDATE_LOADING':          return {...state, isLoading: action.isLoading}
  case 'UPDATE_LOGIN_SUCCESS':    return {...state, isLogInSuccess: action.isLogInSuccess}
  case 'UPDATE_HOME_PATH':        return {...state, homePath: action.homePath}
  case 'UPDATE_USER_INFO':        return { ...state, userInfo: { ...state.userInfo, ...action.payload } }
  default:                        return {...state}
  }
}

const mapUserInfo = (user, accessToken, userConfigData, userFundAccesses, userAssetCountry) => {
  return {
    userLoggedIn: true,
    userName: user.email,
    userRole: user?.cheddarRole ?? 'Users',
    roleId: user?.cheddarRoleId ?? null,
    userId: user?.cheddarUserId ?? null,
    auth0connectionStrategy: user?.connectionStrategy,
    auth0connectionName: user?.connectionName,
    token: accessToken,
    userFundAccessType: userFundAccesses,
    authorisedModules: user.authorisedModules,
    languagePreference: Object.values(LOCALES).includes(userConfigData.languagePreference) ? userConfigData.languagePreference : LOCALES[userConfigData.languagePreference.toUpperCase()],
    unitSystem: userConfigData.unitSystem,
    currencyUnit: userConfigData.currencyUnit,
    connectionStep: userConfigData.connectionStep,
    validateLocationUS: userAssetCountry,
    instanceDescription: user.instanceDescription,
    instanceUrlName: user.instanceName,
    emailNotifications: userConfigData.emailNotifications
  }
}

export const UserInfoProvider = ({ children }) => {
  const { isLoading, user, isAuthenticated, getAccessTokenSilently, logout, error } = useAuth0()
  const featureFlagContext = useContext(FeatureFlagContext)
  const [ token, setToken ] = useState()
  const [ flags, setFlags ] = useState()
  const [ loginState, loginDispatch ] = useReducer(loginReducer, loginStateInit)
  const history = useHistory()

  // These calls need to be triggered in the following order: 
  // we need to get the access token from auth0 only if the isAuthenticaed bool from auth0 comes back true
  // We then need to set the axios instance with the bearer token with the value from the auth0 access token
  // once this sequence has completed we can make calls to cheddar to request data to set a complete userInfo object in the LoginAuth0 provider
  // what is triggering the isAuthenticated bool? what would happen if the token expires and we try and call getTokenSilently
  const { data: accessToken, isSuccess: accessTokenSuccess, isError: accessTokenIsError, error: accessTokenError } = useQuery(['accessToken'], getAccessTokenSilently, {refetchOnWindowFocus: false, enabled: !isAuthenticated}) 
  const { data: userConfigData, isSuccess: configSuccess } = useQuery(['userConfigData'], GetUsersConfigurationData, {refetchOnWindowFocus: false, enabled: !!accessToken && token})
  const { data: userFundAccesses, isSuccess: fundAccessSuccess } = useQuery(['userFundAccesses'], GetUserFundAccesses, {refetchOnWindowFocus: false, enabled: !!accessToken  && token})
  const { data: userAssetCountry, isSuccess: assetCountrySuccess }= useQuery(['userAssetCountry'], ValidateUserAssetCountry, {refetchOnWindowFocus: false, enabled: !!accessToken  && token})

  const isSuccess = accessTokenSuccess && configSuccess && fundAccessSuccess && assetCountrySuccess
  const queryParams = new URLSearchParams(window.location.search)
  const cbError = window.location.pathname === '/callback' && queryParams.get('error')
  const cbErrorDescription = window.location.pathname === '/callback' && queryParams.get('error_description')

  if (cbError || cbErrorDescription || error) console.error('SIERA. UserInfoProvider.js Auth0 error:', cbError, cbErrorDescription, error)

  const logOutOfAuthAndApp = (logoutParams) => {
    if(!unsavedChanges.value){
      logout({logoutParams: logoutParams || {returnTo: window.location.origin + '/login'}})
      localStorage.removeItem('userInfo')
      localStorage.removeItem('dpTimePeriod')
      localStorage.removeItem('dpCustomTimePeriod')
      CachedUserState.clearUserDataCache()
      SessionUserState.clearUserDataFromSession()
    }else{
      history.push( '/login')
    }
  }

  const changePassword = useMutation({
    mutationFn: () => {
      return axiosAuthInstance.post('dbconnections/change_password', {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: user.email,
        connection: 'Username-Password-Authentication',
        organization: user.org_id
      }).then((response) => {
        // Show a message to the user to notify them the email to reset the password has been sent
      })
    }
  })

  const sendMetricsToPendo = (id, email, role, instanceName) => {
    if (window.pendo) {
      window.pendo.initialize({
        apiKey: '00b224be-e22f-468e-40cc-5f976075fe23',
        visitor: { id: id, email: email, role: role },
        account: { id: instanceName }
      })
    }
  }

  // Without `useMemo()` SonarCloud throws the warning:
  // "The object passed as the value prop to the Context provider changes every render. To fix this consider wrapping it in a useMemo hook."
  const userInfoMemoised = useMemo(() => {
    return { loginState, loginDispatch, isAuthenticated, logOutOfAuthAndApp, changePassword }
  }, [loginState, loginDispatch, isAuthenticated, logOutOfAuthAndApp, changePassword])

  // SSO fix
  // If the access token is invalid, clear the local storage and log out
  useEffect(() => {
    if (accessTokenIsError || accessTokenError?.includes('Unknown or invalid refresh token')) {
      localStorage.clear()
      logout({ logoutParams: { returnTo: window.location.origin + '/login' } })
    }
  }, [accessTokenIsError, accessTokenError, logout])

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently)
  }, [])

  useEffect(() => {
    if (!userConfigData || featureFlagContext.featureState.initialised || flags) return
    async function getFeatureFlagsForFirstTime() {
      const flags = await getFeatureFlagsValue(featureFlagInit, mapUserInfo(user, accessToken, userConfigData, userFundAccesses, userAssetCountry), featureFlagContext)
      featureFlagContext.featureFlagDispatcher({...featureFlagContext.featureState, ...flags})
      setFlags(flags)
    }
    getFeatureFlagsForFirstTime()
  }, [userConfigData])

  useEffect(() => {
    if (!accessToken || !user || !featureFlagContext.featureState.initialised) return
    setToken(true)
    CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.loginTimestamp, new Date())
    loginDispatch({ type: 'UPDATE_USER', user: user })
    loginDispatch({ type: 'UPDATE_LOGIN_SUCCESS', isLogInSuccess: isSuccess })

    if (!userConfigData || !userFundAccesses) return
    const userInfo = mapUserInfo(user, accessToken, userConfigData, userFundAccesses, userAssetCountry)
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    loginDispatch({ type: 'SET_USER_INFO', userInfo })
    loginDispatch({ type: 'UPDATE_USER_FUND_ACCESS', userFundAccess: USER_FUND_ACCESSES[userFundAccesses] })
    loginDispatch({ type: 'UPDATE_LOGIN_STATE', isLoggedIn: isAuthenticated })

    async function setHomePagePath() {
      const homePath = await getHomePagePathV2(featureFlagContext.featureState.sieraplus_fundViews, USER_FUND_ACCESSES[userFundAccesses], userInfo.instanceUrlName)
      loginDispatch({ type: 'UPDATE_HOME_PATH', homePath })
    }
    setHomePagePath()
    
    sendMetricsToPendo(userInfo.userId, userInfo.userName, userInfo.userRole, userInfo.instanceUrlName)
  }, [accessToken, userConfigData, userFundAccesses, userAssetCountry, featureFlagContext.featureState])

  return (
    <I18nProvider locale={loginState.userInfo?.languagePreference}>
      <UserInfoContext.Provider value={userInfoMemoised}>
        <Switch>
          <Route path="/switch-instance" component={SwitchInstance} />
          {(cbError || error) && <AuthError /> }
          {!cbError && !isLoading && !isAuthenticated && <Auth0Login />}
          {isSuccess && featureFlagContext.featureState.initialised && Object.keys(loginState.userInfo).length !== 0 && children}
        </Switch>
      </UserInfoContext.Provider>
    </I18nProvider>
  )
}

export const useLoginAuthContext = () => useContext(UserInfoContext)