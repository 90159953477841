import {useContext, useMemo, useState, useEffect} from 'react'
import {FeatureFlagContext} from 'Contexts'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {GetPassportData} from '../../services/assets/passport-service'
import {noRefetchOnWindowFocus} from '../../services/common/useQuery-config'
import {  ALPHABETS_REGEX, DATA_FROM,  fundDataQualityUtilitiesList,  fundPerformanceUtilitiesList,
  UTILITY_LABELS_OBJECT_TITLE_CASE,  UTILITY_OTHER_NAME_MAP} from '../helpers/Constants'
import {
  EXPORT_TYPES, LEVEL_OF_DETAILS, LEVEL_OF_DETAILS_EXCEL_VALUE,
  LEVEL_OF_DETAILS_FOR_EXPORT,
  TIME_PERIOD_GRANULARITY, TIME_PERIOD_GRANULARITY_EXCEL_VALUE,
  TIME_PERIOD_GRANULARITY_FOR_EXPORT
} from './ExportHelper'
import {
  GetAssetActionPlanExportData,
  GetAssetDataQualityExportData,
  GetAssetDataQualityExportDataV2,
  GetAssetPerformanceExportData
} from '../../services/assets/portfolio-service'
import I18n from '../i18n/I18n'
import {createEndMonthParam, formatDateToDdMonYy, getDropdownItemTemplate, getLocalizedValue, getNotificationTemplate} from '../helpers/Helper'
import ActionPdf from '../../components/pages/assets/action-plan/export-to-pdf/ActionPdf'
import {pdf} from '@react-pdf/renderer'
import PassportPdf from '../../components/pages/assets/passport/export-to-pdf/PassportPdf'
import html2canvas from 'html2canvas'
import PerformancePdf from '../../components/pages/assets/performance/export-to-pdf/PerformancePdf'
import DataQualityPdf from '../../components/pages/assets/data-quality/export-to-pdf/DataQualityPdf'
import MeterPdf from '../../components/pages/assets/data-quality/export-to-pdf/MeterPdf'
import {AssetActionPlanExcelHelper} from './asset-exports/excel-exports/AssetActionPlanExcelHelper'
import {AssetDataQualityExcelHelper} from './asset-exports/excel-exports/AssetDataQualityExcelHelper'
import {AssetHomeAssetDetailsExcelHelper} from './asset-exports/excel-exports/AssetHomeAssetDetailsExcelHelper'
import {exportAssetHomePerformanceExcel} from './asset-exports/excel-exports/assetHomePerformanceExportHelper'
import {utilityName} from './asset-exports/excel-exports/AssetPerformanceExcelHelper'
import {AssetHomeActionPlanExcelHelper} from './asset-exports/excel-exports/AssetHomeActionPlanExcelHelper'
import {AssetHomeDataQualityExportHelper} from './asset-exports/excel-exports/AssetHomeDataQualityExcelHelper'
import {Checkbox} from 'primereact/checkbox'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import JSZip from 'jszip'
import { useIntl } from 'react-intl'
import { fetchPerformanceUtilitySummaryExport } from '../../services/assets/performance-service'
import SpinningLoader from '../../components/common/spinning-loader/SpinningLoader'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { dataQualityExportV2 } from './data-quality-v2/dataQualityExportV2'


export const DefaultExportSelection = {
  asset_home_performance_excel_export: {
    utility: {label: I18n('t_alu'), value: 'All'},
    utility_dropdown_options: fundPerformanceUtilitiesList,
    level_of_detail:  {label: I18n('t_level_of_details_by_asset'), value: LEVEL_OF_DETAILS.asset, excelValue : LEVEL_OF_DETAILS_EXCEL_VALUE.asset},
    level_of_detail_dropdown_options : LEVEL_OF_DETAILS_FOR_EXPORT,
    time_period_granularity : {label: I18n('t_time_period_granularity_annual'), value: TIME_PERIOD_GRANULARITY.ANNUAL , excelValue : TIME_PERIOD_GRANULARITY_EXCEL_VALUE.ANNUAL},
    time_period_granularity_dropdown_options:TIME_PERIOD_GRANULARITY_FOR_EXPORT,
  },
  asset_performance_all_utility_excel_export: {
    utility: {label: I18n('t_alu'), value: 'All'},
    utility_dropdown_options: fundPerformanceUtilitiesList,
    level_of_detail:  {label: I18n('t_level_of_details_by_meter'), value: LEVEL_OF_DETAILS.meter, excelValue : LEVEL_OF_DETAILS_EXCEL_VALUE.meter},
    level_of_detail_dropdown_options : LEVEL_OF_DETAILS_FOR_EXPORT.filter((option) => option.value === LEVEL_OF_DETAILS.meter),
    time_period_granularity : {label: I18n('t_time_period_granularity_monthly'), value: TIME_PERIOD_GRANULARITY.MONTHLY , excelValue : TIME_PERIOD_GRANULARITY_EXCEL_VALUE.MONTHLY},
    time_period_granularity_dropdown_options:TIME_PERIOD_GRANULARITY_FOR_EXPORT.filter((option) => option.value === TIME_PERIOD_GRANULARITY.MONTHLY),
  },
  asset_home_data_quality_excel_export: {
    utility: {label: I18n('t_alu'), value: 'All'},
    utility_dropdown_options: fundDataQualityUtilitiesList,
  },
}

const AssetExportSelection = (props) => {
  // IMPORTANT !!! : DO WE NEED THE LANGUAGE PROP
  const [exportSelection, setSelection] = useState([])
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const intl = useIntl()
  const {assetId, fundName} = useParams()
  const assetData = useQuery(['passportData', assetId], !props?.exportDetails?.portfolio && GetPassportData, noRefetchOnWindowFocus)
  const imageExportOptions = {
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
    scale: 3,
    display: 'block',
  }
  const dpCustomTime = JSON.parse(localStorage.getItem('dpCustomTimePeriod'))
  const timeNoOfMonthsPeriod = dpCustomTime?.pickerValueMonths
  const timeEndDatePeriod = dpCustomTime && createEndMonthParam(dpCustomTime.pickerValueEndYear, dpCustomTime.pickerValueEndMonth)
  const [ assetHomeExportSelections, setAssetHomeExportSelections] = useState(DefaultExportSelection)
  const selectedUtility = assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT].utility.value
  const variablesExcel = ['assetPerformanceData', assetId, props.exportDetails?.time, selectedUtility, timeNoOfMonthsPeriod, timeEndDatePeriod]
  const { refetch: refetchUtilitySummary } = useQuery(variablesExcel, fetchPerformanceUtilitySummaryExport, { ...noRefetchOnWindowFocus, enabled: false })
  const queryParamsForAssetHomeExcelExport = useMemo(() => {
    const {
      filterFundSelected,
      filterLocationSelected,
      filterSectorSelected,
      searchTerm,
    } = props?.exportDetails?.filterSelection || {}
    return {
      fundSelected: filterFundSelected,
      locationSelected: filterLocationSelected,
      sectorSelected: filterSectorSelected,
      searchTerm: searchTerm,
      time: props?.exportDetails?.period,
      noOfMonths: props?.exportDetails?.noOfMonths,
      endDate: props?.exportDetails?.endDate,
    }

  }, [assetHomeExportSelections,props?.exportDetails?.noOfMonths,props?.exportDetails?.endDate])
  const queryParamsForAssetHomePerformanceExcelExport = {
    ...queryParamsForAssetHomeExcelExport,
    selectedUtility:
        assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT].utility.value === UTILITY_OTHER_NAME_MAP.All ?
          UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY : assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT].utility.value,
    time_period_granularity: assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT].time_period_granularity.value === TIME_PERIOD_GRANULARITY.MONTHLY,
    endPointPath: assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT].level_of_detail.value === LEVEL_OF_DETAILS.asset ? 'PerformanceExportDataAssetsAnnually':'PortfolioExportDataForMeters',

  }
  const queryParamsForAssetHomeDataQualityExcelExport = {
    ...queryParamsForAssetHomeExcelExport,
    selectedUtility:
        assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT].utility.value === UTILITY_OTHER_NAME_MAP.All ?
          UTILITY_OTHER_NAME_MAP.All :
          assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT].utility.value,
  }

  const {refetch: reftechAssetDataQualityExportData, isFetching: isFetchingAssetDataQualityExport } = useQuery(['assetDataQualityExportData', queryParamsForAssetHomeDataQualityExcelExport], GetAssetDataQualityExportData,
    { ...noRefetchOnWindowFocus, enabled: false})
  const {refetch: refetchAssetDataQualityExportDataV2, isFetching: isFetchingAssetDataQualityExportV2 } = useQuery(['assetDataQualityExportDataV2', queryParamsForAssetHomeExcelExport, unitSystem], GetAssetDataQualityExportDataV2,
    { ...noRefetchOnWindowFocus, enabled: false})

  const { refetch: refetchAssetPerformanceExportData, isFetching: isFetchingAssetPerformanceExport } = useQuery(  ['assetPerformanceExportData', queryParamsForAssetHomePerformanceExcelExport],  GetAssetPerformanceExportData,{ ...noRefetchOnWindowFocus, enabled: false})

  const { refetch: refetchAssetActionPlanExportData, isFetching: isFetchingAssetActionPlanExport } = useQuery(['assetActionPlanExportData',queryParamsForAssetHomeExcelExport], GetAssetActionPlanExportData, {...noRefetchOnWindowFocus, enabled : false})


  function findFilterValue(value) {
    if (value) {
      return value
    }
    return getLocalizedValue(selectedLanguage, 't_all')
  }

  const getFilterSelectionValue = useMemo(() => {
    if (props?.exportDetails.filterSelection) {
      const {
        filterFundSelected,
        filterLocationSelected,
        filterSectorSelected,
      } = props?.exportDetails?.filterSelection || {}
      return [
        [`${getLocalizedValue(selectedLanguage, 't_fund')}: ${findFilterValue(  filterFundSelected)}`],
        [`${getLocalizedValue(selectedLanguage,  't_location')}: ${findFilterValue(filterLocationSelected)}`],
        [`${getLocalizedValue(selectedLanguage, 't_type')}: ${findFilterValue(filterSectorSelected)}`],
        [`${getLocalizedValue(selectedLanguage, 't_date_of_export')}: ${formatDateToDdMonYy()}`]
      ]
    }
    return []
  }, [props?.exportDetails.filterSelection])

  useEffect(() => {
    setAssetHomeExportSelections(DefaultExportSelection)
    return () => {
      setSelection([])
    }
  }, [])

  //region Action plan Exports
  function setActionPdfData(data) {
    let agreedActions = []
    let actionsUnderReview = []
    let completedAction = []
    let applicableAction = []
    if (data) {
      for (const individualAction of data) {
        if (individualAction.action.status === 'AgreedActions') {
          agreedActions.push(individualAction)
        } else if (individualAction.action.status === 'Applicable') {
          applicableAction.push(individualAction)
        } else if (individualAction.action.status === 'ActionsUnderReview') {
          actionsUnderReview.push(individualAction)
        } else if (individualAction.action.status === 'Completed') {
          completedAction.push(individualAction)
        }
      }
    }
    return {agreedActions,applicableAction,completedAction,actionsUnderReview}
  }

  function getStatusLabel(individualActionData, isOverdue) {
    if (individualActionData.status === 'Completed' || individualActionData.completion) {
      return 'Completed'
    } else if (individualActionData.dueDate && isOverdue) {
      return 'Overdue'
    } else {
      return individualActionData.status
    }
  }

  function setActionPdfDataV2(data) {
    let agreedActions = []
    let actionsUnderReview = []
    let completedAction = []
    let applicableAction = []
    let overdueAction = []
    if (data) {
      for (const individualActionData of data) {
        const dueDate = new Date(individualActionData.dueDate)
        const isOverdue = dueDate < new Date()
        let statusLabel = getStatusLabel(individualActionData, isOverdue)
        const individualAction = {...individualActionData, status: statusLabel}
        if (individualAction.status === 'AgreedActions') {
          agreedActions.push(individualAction)
        } else if (individualAction.status === 'Applicable') {
          applicableAction.push(individualAction)
        } else if (individualAction.status === 'ActionsUnderReview') {
          actionsUnderReview.push(individualAction)
        } else if (individualAction.status === 'Completed' || individualAction.completion) {
          completedAction.push(individualAction)
        } else if(individualAction.status === 'Overdue'){
          overdueAction.push(individualAction)
        }
      }
    }
    return {agreedActions,applicableAction,completedAction,actionsUnderReview,overdueAction}
  }

  async function actionPdf() {
    let {agreedActions,applicableAction,completedAction,actionsUnderReview} = featureState.sieraplus_assetActionPlan ? setActionPdfDataV2(props?.exportDetails.actionData) : setActionPdfData(props?.exportDetails.actionData)
    const doc = (
      <ActionPdf
        agreedAction={agreedActions}
        actionReview={actionsUnderReview}
        completedAction={completedAction}
        applicableAction={applicableAction}
        asset={await assetData.data.assetInfo}
        language={props?.exportDetails.language}
        featureState={featureState}
      />
    )
    const asPdf = pdf([]) // {} is important, throws without an argument
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()
    saveAs(blob, getLocalizedValue(selectedLanguage, 't_action_pdf'))
  }

  //endregion

  //region Passport Exports

  async function passportPdf() {
    if (props?.exportDetails) {
      const {exportDetails} = props
      const electricity = exportDetails.electricity.current.getCanvas().toDataURL('image/png', 1.0)
      const thermal = exportDetails.thermal.current.getCanvas().toDataURL()
      const water = exportDetails.water.current.getCanvas().toDataURL()
      const carbon = exportDetails.carbon.current.getCanvas().toDataURL()
      const waste = exportDetails.waste.current.getCanvas().toDataURL()

      const doc = (
        <PassportPdf
          utility={exportDetails.utility}
          data={exportDetails.data}
          electricity={electricity}
          thermal={thermal}
          water={water}
          waste={waste}
          carbon={carbon}
          assetImage={exportDetails.assetImage}
          asset={await assetData.data.assetInfo}
          lastRefurbished={exportDetails.lastRefurbished}
          certifications={exportDetails.certificates}
          language={exportDetails.language}
        />
      )

      const asPdf = pdf([])
      asPdf.updateContainer(doc)
      const blob = await asPdf.toBlob()
      saveAs(blob, getLocalizedValue(selectedLanguage, 't_passport_pdf'))
    }
  }

  async function passportImage() {
    const passportGraphImage = await html2canvas(
      document.getElementById('passportImages'),
      imageExportOptions
    )
    saveAs(passportGraphImage.toDataURL(), 'Passport Graphs.jpg')
  }

  //endregion

  function structurePerformanceData(data) {
    return data?.map((event) => ({
      description: getNotificationTemplate(event),
      date: event.impactDate,
      person: event.userAssigned,
      notes: event.notes,
    }))
  }

  async function performancePdf() {
    const perfData = structurePerformanceData(props?.exportDetails.performanceData)
    const performanceImage = document.getElementById('performance-chart-for-pdf-img-export').toDataURL('image/png')
    const doc = (
      <PerformancePdf
        image={performanceImage}
        performanceData={perfData}
        utility={props?.exportDetails.utility}
        asset={await assetData.data?.assetInfo}
        language={props?.exportDetails.language}
        overview={props?.exportDetails.overview}
        showTarget={props?.exportDetails.showTarget}
        timePeriod={props?.exportDetails.timePeriod}
      />
    )

    const asPdf = pdf([])
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()
    saveAs(blob, getLocalizedValue(selectedLanguage, 't_perf_pdf'))
  }

  async function performanceImage() {
    const performanceImage = await html2canvas(document.getElementById('performance-for-pdf-img-export'), imageExportOptions)
    saveAs(performanceImage.toDataURL(), 'Performance Graph.jpg')
  }

  //endregion

  //region DataQuality Exports

  function groupBy(arr, prop) {
    let alteredArray = []
    arr.forEach((data) => {
      data.date = data.dateSummary.replace(ALPHABETS_REGEX, '')
      alteredArray.push(data)
    })
    const map = new Map(Array.from(alteredArray, (obj) => [obj[prop], []]))
    arr.forEach((obj) => map.get(obj[prop]).push(obj))
    return Array.from(map.values())
  }
  async function dataQualityPdf() {
    const canvasL = await html2canvas(  document.getElementById('landlord'),  imageExportOptions)
    const canvasT = await html2canvas(  document.getElementById('tenant'),  imageExportOptions)
    const canvasM = await html2canvas(  document.getElementById('dqMeter'),  imageExportOptions)

    const landlord = canvasL.toDataURL('image/png,1.0')
    const tenant = canvasT.toDataURL('image/png,1.0')
    const dqMeter = canvasM.toDataURL('image/png,1.0')

    let groupedLandlordArray = props?.exportDetails.landlord? groupBy(props?.exportDetails.landlord, 'date'): []
    let groupedTenantArray = props?.exportDetails.tenant? groupBy(props?.exportDetails.tenant, 'date'): []
    const docLandlord = (
      <DataQualityPdf
        asset={await assetData.data.assetInfo}
        language={selectedLanguage}
        image={landlord}
        areaData={groupedLandlordArray}
        utility={props?.exportDetails.utilityL}
        area={props?.exportDetails.areaL}
        landlord={true}
        qtrLabel={props?.exportDetails.qtrLabelLandlord}
        period={props?.exportDetails.period}
        colorLabel={props?.exportDetails.colorLabelLandlord}
      />
    )
    const asPdfLandlord = pdf([])
    asPdfLandlord.updateContainer(docLandlord)
    const blobLandlord = await asPdfLandlord.toBlob()

    const docTenant = (
      <DataQualityPdf
        asset={await assetData.data.assetInfo}
        language={selectedLanguage}
        image={tenant}
        areaData={groupedTenantArray}
        utility={props?.exportDetails.utilityT}
        area={props?.exportDetails.areaT}
        qtrLabel={props?.exportDetails.qtrLabelTenant}
        colorLabel={props?.exportDetails.colorLabelTenant}
        period={props?.exportDetails.period}
      />
    )
    const asPdfTenant = pdf([])
    asPdfTenant.updateContainer(docTenant)
    const blobTenant = await asPdfTenant.toBlob()

    const docMeter = (
      <MeterPdf
        asset={await assetData.data.assetInfo}
        meterSrc={dqMeter}
        period={props?.exportDetails.period}
        utility={props?.exportDetails.utilityM}
        area={props?.exportDetails.areaM}
        language={selectedLanguage}
      />
    )
    const asPdfMeter = pdf([]) // {} is important, throws without an argument
    asPdfMeter.updateContainer(docMeter)
    const blobMeter = await asPdfMeter.toBlob()

    let zip = new JSZip()
    let img = zip.folder(  getLocalizedValue(selectedLanguage, 't_data_quality_zip')) //folder name
    img.file(  getLocalizedValue(selectedLanguage, 't_dq_tenant_pdf'),  blobTenant)
    img.file(  getLocalizedValue(selectedLanguage, 't_dq_landlord_pdf'),  blobLandlord)
    img.file(getLocalizedValue(selectedLanguage, 't_dq_meter_pdf'), blobMeter)
    zip.generateAsync({type: 'blob'}).then(function (content) {saveAs(  content,  getLocalizedValue(selectedLanguage, 't_data_quality_zip'))
    })
  }

  async function dataQualityImage() {
    const landlordImage = await html2canvas(  document.getElementById('landlordImage'),  imageExportOptions)
    const tenantImage = await html2canvas(  document.getElementById('tenantImage'),  imageExportOptions)
    const meterImage = await html2canvas(  document.getElementById('dqMeterImage'),  imageExportOptions)
    let zip = new JSZip()
    let img = zip.folder(
      getLocalizedValue(selectedLanguage, 't_data_quality_zip')
    ) //folder name
    let base64StringL = landlordImage.toDataURL().replace('data:image/png;base64,', '')
    let base64StringT = tenantImage.toDataURL().replace('data:image/png;base64,', '')
    let base64StringM = meterImage.toDataURL().replace('data:image/png;base64,', '')
    img.file('Landlord.jpg', base64StringL, {base64: true})
    img.file('Tenant.jpg', base64StringT, {base64: true})
    img.file('Meter.jpg', base64StringM, {base64: true})
    zip.generateAsync({type: 'blob'}).then(function (content) {saveAs(  content,  getLocalizedValue(selectedLanguage, 't_data_quality_zip'))
    })
  }


  //endregion

  async function exportToPdf() {
    if (props?.exportDetails) {
      const {actionPlan, passport, performance, dataQuality, portfolio} = props.exportDetails
      if (actionPlan) {
        await actionPdf()
      } else if (passport) {
        await passportPdf()
      } else if (performance) {
        await performancePdf()
      } else if (dataQuality) {
        await dataQualityPdf()
      }
    }
  }
  async function exportToExcel() {
    if (props?.exportDetails) {
      const {actionPlan, dataQuality, portfolio} = props.exportDetails
      if (actionPlan) {
        let {agreedActions,applicableAction, completedAction,actionsUnderReview,overdueAction} = featureState.sieraplus_assetActionPlan ? setActionPdfDataV2(props?.exportDetails.actionData) : setActionPdfData(props?.exportDetails.actionData)
        await AssetActionPlanExcelHelper(props, selectedLanguage, unitSystem, currencyUnit, agreedActions, applicableAction, completedAction, actionsUnderReview, overdueAction, featureState)
      } else if (dataQuality) {
        await AssetDataQualityExcelHelper(props, selectedLanguage, unitSystem)
      } else if (portfolio) {
        await AssetHomeAssetDetailsExcelHelper(props, getFilterSelectionValue, selectedLanguage)
      }
    }
  }

  async function exportToImage() {
    if (props?.exportDetails) {
      const {passport, dataQuality, performance} = props.exportDetails
      if (passport) {
        await passportImage()
      } else if (dataQuality) {
        await dataQualityImage()
      } else if (performance) {
        await performanceImage()
      }
    }
  }

  const fetchExcelData = async (param) => {
    try {
      return await param()
    } catch (error) {
      console.error('Error:', error)
      throw error
    }
  }

  const closeOverlay = () => {
    props?.exportDetails?.closeOverlay()
  }

  async function handleExport() {
    if (exportSelection?.includes(EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT)) {
      fetchExcelData(refetchAssetPerformanceExportData).then((response) => {
        exportAssetHomePerformanceExcel({props, getFilterSelectionValue,selectedLanguage,assetHomeExportSelections, assetPerformanceExportData: response?.data, intl, featureState, type: 1})
        closeOverlay()
      })
    }
    if (exportSelection?.includes(EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT) ||
          exportSelection?.includes(EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_SINGLE_UTILITY_EXCEL_EXPORT)) {
      fetchExcelData(refetchUtilitySummary).then((response) => {
        const titleHead = [
          [`${getLocalizedValue(selectedLanguage, 't_fund')}: ${fundName}`],
          [`${getLocalizedValue(selectedLanguage, 't_date_of_export')}: ${formatDateToDdMonYy()}`]
        ]
        exportAssetHomePerformanceExcel({props, getFilterSelectionValue: titleHead,selectedLanguage,assetHomeExportSelections, assetPerformanceExportData: response, intl, featureState, type: 2})
        closeOverlay()
      })
    }
    if (exportSelection?.includes(EXPORT_TYPES.ASSET.ASSET_HOME_ACTION_PLAN_EXCEL_EXPORT)) {
      fetchExcelData(refetchAssetActionPlanExportData).then((response) => {
        AssetHomeActionPlanExcelHelper(props, response?.data,selectedLanguage,getFilterSelectionValue)
        closeOverlay()
      })
    }
    if(exportSelection?.includes(EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT)){
      let selectedUtility = assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT].utility.value
      fetchExcelData(reftechAssetDataQualityExportData).then((response) => {
        AssetHomeDataQualityExportHelper(props.exportDetails,selectedLanguage, response?.data,selectedUtility, intl)
        closeOverlay()
      })
    }
    if(exportSelection?.includes(EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT_V2)){
      fetchExcelData(refetchAssetDataQualityExportDataV2).then((response) => {
        dataQualityExportV2({exportData: response?.data, sheetHeaderProps: props.exportDetails, formatMessage: intl.formatMessage, selectedLanguage, unitSystem, isPortfolio: true})
        closeOverlay()
      })
    }
    if (exportSelection?.includes(EXPORT_TYPES.ASSET.PDF_EXPORT)) {
      await exportToPdf()
    }
    if (exportSelection?.includes(EXPORT_TYPES.ASSET.EXCEL_EXPORT)) {
      await exportToExcel()
    }
    if (exportSelection?.includes(EXPORT_TYPES.ASSET.JPG_EXPORT)) {
      await exportToImage()
    }
    if (exportSelection?.includes(EXPORT_TYPES.ASSET.PDF_EXPORT) || exportSelection?.includes(EXPORT_TYPES.ASSET.EXCEL_EXPORT) || exportSelection?.includes(EXPORT_TYPES.ASSET.JPG_EXPORT))
      closeOverlay()
  }

  function getExportPerformanceExcel() {
    switch (props?.exportDetails?.exportFrom['from']) {
    case DATA_FROM.ASSET.ASSET_PERFORMANCE:
      return (
        <>
          <div className="export-checkbox">
            <Checkbox value={EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT} onChange={onSelectionChange}
              checked={exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT) !== -1}
              data-testid={EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT}/>
            <label className="label-checkbox" data-testid='asset-performance-all-utilities'>{I18n('t_all_utilities_excel')}</label>
          </div>
          <div className="export-checkbox">
            <Checkbox value={EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_SINGLE_UTILITY_EXCEL_EXPORT} onChange={onSelectionChange}
              checked={exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_SINGLE_UTILITY_EXCEL_EXPORT) !== -1}
              data-testid={EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_SINGLE_UTILITY_EXCEL_EXPORT}/>
            <label className="label-checkbox">{utilityName(props?.exportDetails?.utility?.name,selectedLanguage)}</label>
          </div>
        </>
      )
    default:
      return null
    }
  }

  const onSelectionChange = (e) => {
    let selection = [...exportSelection]
    if (e.checked) {
      selection.push(e.value)
    } else {
      selection.splice(selection.indexOf(e.value), 1)
    }

    if (props?.fetchExportSelection) {
      props.fetchExportSelection(selection)
    }
    setSelection(selection)
  }

  function getLabelForExportExcel() {
    if (props?.exportDetails.portfolio) {
      return (
        <label className="label-checkbox">{I18n('t_asset_details_excel')}</label>
      )
    } else {
      return <label className="label-checkbox">{I18n('t_excel')}</label>
    }
  }

  function getExportOption1() {
    switch (props?.exportDetails?.exportFrom['from']) {
    case DATA_FROM.ASSET.ASSET_DATA_QUALITY:
    case DATA_FROM.ASSET.ASSET_PORTFOLIO:
    case DATA_FROM.ASSET.ASSET_ACTION_PLAN:
      return (
        <div
          className={`export-checkbox excel-export-checkbox ${props.disableExcelExport ? 'disabled' : 'enabled'}`}>
          <Checkbox value={EXPORT_TYPES.ASSET.EXCEL_EXPORT} onChange={onSelectionChange}
            checked={exportSelection.indexOf(EXPORT_TYPES.ASSET.EXCEL_EXPORT) !== -1}
            disabled={props?.exportDetails.disableExcelExport}
            aria-label={EXPORT_TYPES.ASSET.EXCEL_EXPORT}/>
          {getLabelForExportExcel()}
        </div>
      )
    default:
      return null
    }
  }

  function getExportOption2() {
    switch (props?.exportDetails?.exportFrom['from']) {
    case DATA_FROM.ASSET.ASSET_DATA_QUALITY:
    case DATA_FROM.ASSET.ASSET_PASSPORT: return (
      <div className="export-checkbox">
        <Checkbox value={EXPORT_TYPES.ASSET.JPG_EXPORT} onChange={onSelectionChange}
          checked={exportSelection.indexOf(EXPORT_TYPES.ASSET.JPG_EXPORT) !== -1}/>
        <label className="label-checkbox">{I18n('t_jpg')}</label>
      </div>
    )
    case DATA_FROM.ASSET.ASSET_PERFORMANCE: return null // removed as per ticket https://evorasiera.atlassian.net/browse/SPR-6218
    default:
      return null
    }
  }

  function getExportOption3() {
    function handleUtilitySelections(e, exportType) {
      let utility = assetHomeExportSelections[exportType].utility_dropdown_options.filter((utility) => utility.label === e.value)[0]
      setAssetHomeExportSelections((prevState) => {
        return {...prevState, [exportType]: {...prevState[exportType], utility: utility}}
      })
    }
    function handleLevelOfDetailSelections(e,exportType) {
      let level_of_detail_value = assetHomeExportSelections[exportType].level_of_detail_dropdown_options.filter((levelOfDetail) => levelOfDetail.label === e.value)[0]
      setAssetHomeExportSelections((prevState) => {
        return {...prevState, [exportType]: {...prevState[exportType], level_of_detail: level_of_detail_value}}
      })
    }
    function handleTimePeriodGranularitySelections(e,exportType) {
      let time_period_granularity =  assetHomeExportSelections[exportType].time_period_granularity_dropdown_options.filter((timePeriodGranularity) => timePeriodGranularity.label === e.value)[0]
      setAssetHomeExportSelections((prevState) => {
        return {...prevState, [exportType]: {...prevState[exportType], time_period_granularity: time_period_granularity}}
      })
    }

    function getAdditionalDropdownOptionsForExport(exportType) {
      function getDropdown(value, options, placeholder, onchangeFunction, exportType, label) {
        return <div className="asset-performance-additional-options-container" data-testid={label}>
          <label className="dropdown-label">{label}</label>
          <Dropdown
            value={value}
            options={options}
            itemTemplate={getDropdownItemTemplate}
            placeholder={placeholder}
            onChange={(e) => onchangeFunction(e, exportType)}
            className="portfolio-export-utility-dropdown"
            role='utility-levelOfDetail-timePeriodGranularity-dropdown'
          />
        </div>
      }

      // common dropdown values
      let utility_dropdown_value = assetHomeExportSelections[exportType].utility.label
      let utility_dropdown_options = assetHomeExportSelections[exportType].utility_dropdown_options.map((utility) => utility.label)
      let utility_dropdown_placeholder = getLocalizedValue(selectedLanguage, 't_alu')
      switch (exportType) {
      case EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT:
      case EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT:
        return <>
          {getDropdown(utility_dropdown_value,
            utility_dropdown_options,
            utility_dropdown_placeholder,
            handleUtilitySelections,
            exportType,
            getLocalizedValue(selectedLanguage,'t_req_utility'))
          }
          {getDropdown(assetHomeExportSelections[exportType].level_of_detail.label,
            assetHomeExportSelections[exportType].level_of_detail_dropdown_options.map(levelOfDetail => levelOfDetail.label),
            getLocalizedValue(selectedLanguage, 't_level_of_details_by_asset'),
            handleLevelOfDetailSelections,
            exportType, getLocalizedValue(selectedLanguage,'t_level_of_detail'))
          }
          {getDropdown(assetHomeExportSelections[exportType].time_period_granularity.label,
            assetHomeExportSelections[exportType].time_period_granularity_dropdown_options.map(timePeriodGranularity => timePeriodGranularity.label),
            getLocalizedValue(selectedLanguage, 't_time_period_granularity_annual'),
            handleTimePeriodGranularitySelections,
            exportType, getLocalizedValue(selectedLanguage,'t_time_period_granularity'))
          }
        </>
      case EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT:
        return <>
          {getDropdown(utility_dropdown_value,
            utility_dropdown_options,
            utility_dropdown_placeholder,
            handleUtilitySelections, exportType,
            getLocalizedValue(selectedLanguage,'t_req_utility'))}
        </>
      default:
        return null
      }
    }

    // execution starts here ...
    switch (props?.exportDetails?.exportFrom['from']) {
    case DATA_FROM.ASSET.ASSET_PORTFOLIO:
      return (
        <>
          <div className="export-checkbox">
            <Checkbox value={EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT} onChange={onSelectionChange}
              checked={exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT) !== -1}
              aria-label={EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT} disabled={!featureState?.sieraplus_assetPerformanceExcelExport}/>
            <label className={`label-checkbox ${!featureState?.sieraplus_assetPerformanceExcelExport ? 'opacity-05' : ''}`}>{I18n('t_asset_performance_excel')} </label>
          </div>
          {exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT) !== -1 &&
                  getAdditionalDropdownOptionsForExport(EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT)
          }
          <div className="export-checkbox">
            <Checkbox
              value={featureState?.sieraplus_assetDataQualityLayoutV2 ? 
                EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT_V2 : 
                EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT} 
              onChange={onSelectionChange}
              checked={featureState?.sieraplus_assetDataQualityLayoutV2 ?
                exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT_V2) !== -1 :
                exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT) !== -1}
              aria-label={featureState?.sieraplus_assetDataQualityLayoutV2 ?
                EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT_V2 :
                EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT}/>
            <label className="label-checkbox">{I18n('t_asset_data_quality_excel')}</label>
          </div>
          {!featureState?.sieraplus_assetDataQualityLayoutV2 && exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT) !== -1 &&
                  getAdditionalDropdownOptionsForExport(EXPORT_TYPES.ASSET.ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT)
          }
          <div className="export-checkbox">
            <Checkbox value={EXPORT_TYPES.ASSET.ASSET_HOME_ACTION_PLAN_EXCEL_EXPORT} onChange={onSelectionChange}
              checked={exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_HOME_ACTION_PLAN_EXCEL_EXPORT) !== -1}
              aria-label={EXPORT_TYPES.ASSET.ASSET_HOME_ACTION_PLAN_EXCEL_EXPORT}/>
            <label className="label-checkbox">
              {I18n('t_asset_action_plans_excel')}
            </label>
          </div>
        </>
      )
    case DATA_FROM.ASSET.ASSET_PERFORMANCE: return (
      <>
        <div className="export-checkbox">
          <Checkbox value={EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT} onChange={onSelectionChange}
            checked={exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT) !== -1}
            data-testid={EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT}/>
          <label className="label-checkbox">{I18n('t_asset_performance_excel')} </label>
        </div>
        {exportSelection.indexOf(EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT) !== -1 &&
              getAdditionalDropdownOptionsForExport(EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT)
        }
      </>
    )

    default:
      return null
    }
  }

  function exportButtonStatus() {
    if (exportSelection?.length === 0) {
      return true
    }
    if(props?.exportDetails?.exportFrom['from'] === DATA_FROM.ASSET.ASSET_PORTFOLIO) {
      return isFetchingAssetDataQualityExport || isFetchingAssetDataQualityExportV2 || isFetchingAssetPerformanceExport || isFetchingAssetActionPlanExport
    }
  }

  function exportButton(){
    if(exportButtonStatus() && exportSelection.length){
      return <Button 
        type="button" 
        label={I18n('t_export')} 
        className="export-option auto-width" 
        data-testid="asset-export-btn"
        loading 
        loadingIcon={
          <SpinningLoader size={22} />
        }
      />
    }
    return <Button type="button" label={I18n('t_export')} onClick={handleExport} className="export-option"
      data-testid="asset-export-btn" disabled={exportButtonStatus()}/>  
  }

  return (
    <div className="flex-column pt-1">
      {/* <div className="export-checkbox">
        <Checkbox value={EXPORT_TYPES.ASSET.PDF_EXPORT} onChange={onSelectionChange}
          checked={exportSelection.indexOf(EXPORT_TYPES.ASSET.PDF_EXPORT) !== -1}
          aria-label={EXPORT_TYPES.ASSET.PDF_EXPORT}/>
        <label className="label-checkbox line-height-1">{I18n('t_pdf')}</label>
      </div> removed as per ticket https://evorasiera.atlassian.net/browse/SPR-6218 */}
      {getExportOption1()}
      {getExportOption2()}
      {getExportOption3()}
      {exportButton()}
    </div>
  )
}

AssetExportSelection.propTypes = {
  exportDetails: PropTypes.object
}

export default AssetExportSelection